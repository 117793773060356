<template>
  <v-data-table 
    dense 
    :headers="headers" 
    :items="reps" 
    item-key="name" 
    class="elevation-1"
  >
    <template v-slot:items="props">
      <td>{{ props.item.repId }}</td>
      <td class="text-xs-right">
        {{ props.item.firstName }}
      </td>
      <td class="text-xs-right">
        {{ props.item.lastName }}
      </td>
      <td class="text-xs-right">
        {{ props.item.email }}
      </td>
      <td class="text-xs-right">
        {{ props.item.phone }}
      </td>
      <td class="justify-center">
        <slot 
          name="action" 
          :representative="props.item"
        >
          <v-icon
            small
            class="mr-2"
          >
            edit
          </v-icon>
        </slot>
      </td>
    </template>
    <v-alert
      v-slot:no-results
      :value="true"
      color="error"
      icon="warning"
    >
      Your search for "{{ search }}" found no results.
    </v-alert>
  </v-data-table>
</template>

<script>
export default {
  name: 'RepListTable',
  props: {
    representatives: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Representative ID',
          align: 'left',
          sortable: false,
          value: 'repId',
        },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: '', value: 'agentId' },
      ],
    }
  },
  computed: {
    reps() {
      return this.representatives
    }
  },
}
</script>