<template>
  <v-container>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          row
          wrap
        >
          <v-flex xs10>
            <h2>{{ heading }}</h2>
            <span class="subheading">
              {{ subheading }}
            </span>
          </v-flex>
        </v-layout>
        <v-divider class="my-3" />
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            Agent
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="700px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  New Agent
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ title }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="editedAgent.companyName"
                            label="Company Name"
                            :rules="[rules.required, rules.length(0, 50)]"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md6
                        >
                          <v-text-field
                            v-model="editedAgent.contactName"
                            label="Contact Name"
                            :rules="[rules.required, rules.length(0, 50)]"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md4
                        >
                          <v-text-field
                            v-model="editedAgent.email"
                            label="Email"
                            :rules="[rules.required, rules.email]"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md4
                        >
                          <v-text-field
                            v-model="editedAgent.phone"
                            label="Phone"
                            :rules="[rules.required]"
                          />
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md4
                        >
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="editedAgent.expireDate"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                          >
                            <v-text-field
                              slot="activator"
                              v-model="editedAgent.expireDate"
                              label="Expire Date"
                              prepend-icon="event"
                              :rules="[rules.required]"
                              required
                            />
                            <v-date-picker
                              v-model="editedAgent.expireDate"
                              @input="$refs.menu1.save(editedAgent.expireDate)"
                            />
                          </v-menu>
                        </v-flex>
                        <v-flex
                          xs12
                          sm12
                          md12
                        >
                          <v-text-field
                            v-model="editedAgent.address"
                            label="Address"
                            :rules="[rules.required, rules.length(0, 100)]"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    flat
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    flat
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="agents"
            :search="search"
          >
            <template v-slot:items="props">
              <td>{{ props.item.agentId }}</td>
              <td class="text-xs-right">
                {{ props.item.companyName }}
              </td>
              <td class="text-xs-right">
                {{ props.item.contactName }}
              </td>
              <td class="text-xs-right">
                {{ props.item.email }}
              </td>
              <td class="text-xs-right">
                {{ props.item.phone }}
              </td>
              <td class="text-xs-right">
                {{ props.item.address }}
              </td>
              <td class="text-xs-right">
                {{ props.item.expireDate }}
              </td>
              <td class="justify-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      @click="selectAgent(props.item)"
                    >
                      supervisor_account
                    </v-icon>
                  </template>
                  <span>Representatives</span>
                </v-tooltip>
                <v-icon
                  small
                  @click="editItem(props.item)"
                >
                  edit
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(props.item)"
                >
                  delete
                </v-icon>
                <agent-email-ddl 
                  :item="props.item"
                />
              </td>
            </template>
            <v-alert
              v-slot:no-results
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog
        v-model="repsDialog"
        max-width="850px"
      >
        <v-card>
          <v-card-title>{{ selectedAgent.companyName }}'s Representatives</v-card-title>
          <v-divider />
          <rep-list-tbl :representatives="reps">
            <template v-slot:action="{ representative }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    @click="selectRep(representative)"
                  >
                    supervisor_account
                  </v-icon>
                </template>
                <span>Students</span>
              </v-tooltip>
            </template>
          </rep-list-tbl>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="appsDialog"
        max-width="850px"
      >
        <v-card>
          <v-card-title>{{ selectedRep.firstName }}'s Applications</v-card-title>
          <v-divider />
          <student-list-tbl :students="applications" />
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import RepListTable from '@/components/representative/RepListTable.vue'
import StudentListTable from '@/components/student/StudentListTable.vue'
import AgentEmailDDL from '@/components/agent/AgentEmailDDL.vue'
import validate from '@/custom/validate'

export default {
  components: {
    'rep-list-tbl': RepListTable,
    'student-list-tbl': StudentListTable,
    'agent-email-ddl' : AgentEmailDDL
  },
  data() {
    return {
      agents: [],
      selectedAgent: {},
      selectedRep: {},
      reps: [],
      applications: [],
      repsDialog: false,
      appsDialog: false,
      editedAgent: {},
      editedIndex: -1,
      search: '',
      dialog: false,
      menu1: false,
      valid: false,
      rules: [],
      headers: [
        {
          text: 'Agent ID',
          align: 'left',
          value: 'agentId',
        },
        { text: 'Company Name', value: 'companyName' },
        { text: 'Contact Name', value: 'contactName' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Address', value: 'address' },
        { text: 'Expire Date', value: 'expireDate' },
        { text: 'Action', value: 'agentId' },
      ],
    }
  },
  computed: {
    heading() {
      return 'Agent'
    },
    subheading() {
      return ''
    },
    title() {
      return this.editedIndex === -1 ? 'New Agent' : 'Edit Agent'
    },
  },
  created() {
    this.rules = validate.rules
    this.$store.dispatch('agents/all')
      .then((data) => { this.agents = data })
  },
  methods: {
    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedAgent = {}
        this.editedIndex = -1
      }, 300)
    },
    save() {
      if (!this.$refs.form.validate()) return
      if (this.editedIndex > -1) {
        this.$store.dispatch('agents/update', this.editedAgent)
          .then((data) => {
            Object.assign(this.agents[this.editedIndex], data)
            this.resetValidation()
            this.close()
          })
      } else {
        this.$store.dispatch('agents/create', this.editedAgent)
          .then((data) => {
            this.agents.push(data)
            this.resetValidation()
            this.close()
          })
      }
    },
    editItem(item) {
      this.editedIndex = this.agents.indexOf(item)
      this.editedAgent = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(agent) {
      if (!confirm('Are you sure you want to delete this item?')) { return }
      this.$store.dispatch('agents/delete', agent)
        .then(() => {
          const index = this.agents.indexOf(agent)
          this.agents.splice(index, 1)
        })
    },
    selectAgent(agent) {
      this.selectedAgent = agent
      this.showRepsByAgentId(agent.id)
    },
    selectRep(rep) {
      this.selectedRep = rep
      this.showStudentsByRepId(rep.id)
    },
    async showStudentsByRepId(repId) {
      await this.$store.dispatch('representatives/allAppsById', repId)
        .then((data) => { this.applications = data })
      this.appsDialog = true
    },
    async showRepsByAgentId(agentId) {
      await this.$store.dispatch('agents/allReps', agentId )
        .then((data) => { this.reps = data })
      this.repsDialog = true
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>
