<template>
  <v-data-table 
    dense 
    :headers="headers" 
    :items="items" 
    item-key="name" 
    class="elevation-1"
  >
    <template v-slot:items="props">
      <td>{{ props.item.applicationId }}</td>
      <td class="text-xs-right">
        {{ props.item.firstName }}
      </td>
      <td class="text-xs-right">
        {{ props.item.lastName }}
      </td>
      <td class="text-xs-right">
        {{ props.item.email }}
      </td>
      <td class="justify-center">
        <slot 
          name="action" 
          :application="props.item"
        />
      </td>
    </template>
    <v-alert
      v-slot:no-results
      :value="true"
      color="error"
      icon="warning"
    >
      Your search for "{{ search }}" found no results.
    </v-alert>
  </v-data-table>
</template>

<script>
export default {
  name: 'StudentListTable',
  props: {
    students: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Application ID',
          align: 'left',
          sortable: false,
          value: 'applicationId',
        },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Action', value: '' },
      ],
    }
  },
  computed: {
    items() {
      return this.students
    }
  },
}
</script>