<template>
  <v-menu
    bottom
    right
  >
    <v-icon 
      slot="activator"
      small
    >
      email
    </v-icon>
    <v-list dense>
      <v-list-tile
        @click="sendSetupLink()"
      >
        <v-list-tile-title>Re-send setup link</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AgentEmailDDL',
  props: ['item'],
  data() {
    return {

    }
  },
  methods: {
    sendSetupLink() {
      this.$store.dispatch('logins/passwordSetup', this.item.email)
        .then(()=> this.$store.dispatch('showMessage', { state: 'success', message: 'Email has been sent.' }))
    }
  }
}
</script>
